function LoginCtrl($auth, $state, User, $rootScope) {
	'ngInject';

	// ViewModel
	const vm = this;

    vm.user = {};

	if($auth.isAuthenticated()) {
		$state.transitionTo('home');
	}

	vm.authenticate = function() {

		var credentials = {
			email: vm.email,
			password: vm.password
		}

		// use Satellizer's $auth service to login
		$auth.login(credentials).then(function() {
			// if success, redirect to home/cms state depending on roles
            vm.user = User.$find('me').$then(function(user) {

                if(window.localStorage.getItem('auth')) {
                    window.localStorage.removeItem('auth');
                }
                var user_data = JSON.stringify(user.$response.data);
                window.localStorage.setItem('auth', user_data);

                for(var i = 0; i < user.roles.length; i++) {
                    if(user.roles[i].slug == 'admin') {
                        window.location.href = '/cms';
                        break;
                    }
                }
				$rootScope.$broadcast('login', null);
                $state.go('home', { reload: true });
			});
		}, function (response) {
			vm.errors = response.data.errors;
		});
	}
}

export default {
  name: 'LoginCtrl',
  fn: LoginCtrl
};
