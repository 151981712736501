function OnConfig($stateProvider, $locationProvider, $urlRouterProvider, $compileProvider, $authProvider, restmodProvider, $sceProvider) {
	'ngInject';

	if (process.env.NODE_ENV === 'production') {
		$compileProvider.debugInfoEnabled(false);
	}

	// restmod config (includes default style)
	restmodProvider.rebase('AMSApi', {
		$config: {
			jsonRootMany: 'data',
			jsonRootSingle: 'data'
		}
	});
    restmodProvider.rebase({
        $config: {
            urlPrefix: 'api/v1'
        }
    });

	// disbale blocking outside url loading (videos)
	$sceProvider.enabled(false);

	// remove #s from urls
	$locationProvider.html5Mode(true);

	 // Satellizer configuration that specifies which API
    // route the JWT should be retrieved from
    $authProvider.loginUrl = '/api/v1/auth';

	// Redirect to the auth state if any other states
	// are requested other than users
	$urlRouterProvider.otherwise('/');

	$stateProvider
	.state('home', {
        url: '/',
		views:{
			main:{
				templateUrl: 'home.html',
		        controller: 'HomeCtrl as home'
			}
		}
    })
    .state('home_auth_hash', {
        url: '/auth/:auth_hash',
		views:{
			main:{
				templateUrl: 'home.html',
		        controller: 'HomeCtrl as home'
			}
		}
    })
    .state('recommendations', {
        url: '/recommendations',
		views:{
			main:{
				templateUrl: 'recommendations.html',
		        controller: 'RecommendationsCtrl as recommendations'
			}
		}
    })
    .state('cocktails_search', {
        url: '/cocktails_search/:search_term',
		views:{
			main:{
				templateUrl: 'cocktails.html',
		        controller: 'CocktailsCtrl as cocktails'
			}
		}
	})
	.state('cocktails_category_value', {
        url: '/cocktails_category_value/:category_value_id',
		views:{
			main:{
				templateUrl: 'cocktails.html',
		        controller: 'CocktailsCtrl as cocktails'
			}
		}
	})
    .state('cocktail', {
        url: '/cocktails/:id',
		views:{
			main:{
				templateUrl: 'cocktail.html',
		        controller: 'CocktailCtrl as cocktail'
			}
		}
    })
    .state('cocktails', {
        url: '/cocktails',
		views:{
			main:{
				templateUrl: 'cocktails.html',
		        controller: 'CocktailsCtrl as cocktails'
			}
		}
    })
	.state('category', {
        url: '/categories/:id',
		views:{
			main:{
				templateUrl: 'category.html',
		        controller: 'CategoryCtrl as category'
			}
		}
    })
    .state('categories', {
        url: '/categories',
		views:{
			main:{
				templateUrl: 'categories.html',
		        controller: 'CategoriesCtrl as categories'
			}
		}
    })
    .state('login', {
        url: '/login',
		views:{
			main:{
				templateUrl: 'login.html',
		        controller: 'LoginCtrl as login'
			}
		}
    })
    .state('register', {
        url: '/register',
		views:{
			main:{
				templateUrl: 'register.html',
		        controller: 'RegisterCtrl as register'
			}
		}
    })
    .state('cms', {
        url: '/cms',
		views:{
			main:{
				templateUrl: 'cms.html',
		        controller: 'CmsCtrl as cms'
			}
		}
    })
    .state('cms_users', {
        url: '/cms/users',
		views:{
			main:{
				templateUrl: 'cms_users.html',
		        controller: 'CmsUsersCtrl as cms_users'
			}
		}
    })
    .state('cms_categories', {
        url: '/cms/categories',
		views:{
			main:{
				templateUrl: 'cms_categories.html',
		        controller: 'CmsCategoriesCtrl as cms_categories'
			}
		}
    })
    .state('cms_category_add', {
        url: '/cms/categories/add',
		views:{
			main:{
				templateUrl: 'cms_category_add.html',
		        controller: 'CmsCategoryAddCtrl as cms_category_add'
			}
		}
    })
    .state('cms_category_edit', {
        url: '/cms/categories/{id}',
		views:{
			main:{
				templateUrl: 'cms_category_edit.html',
		        controller: 'CmsCategoryEditCtrl as cms_category_edit'
			}
		}
    })
    .state('cms_category_values', {
        url: '/cms/category_values',
		views:{
			main:{
				templateUrl: 'cms_category_values.html',
		        controller: 'CmsCategoryValuesCtrl as cms_category_values'
			}
		}
    })
    .state('cms_category_value_add', {
        url: '/cms/category_values/add/:category_id',
		views:{
			main:{
				templateUrl: 'cms_category_value_add.html',
		        controller: 'CmsCategoryValueAddCtrl as cms_category_value_add'
			}
		}
    })
    .state('cms_category_value_edit', {
        url: '/cms/category_values/{id}',
		views:{
			main:{
				templateUrl: 'cms_category_value_edit.html',
		        controller: 'CmsCategoryValueEditCtrl as cms_category_value_edit'
			}
		}
    })
    .state('cms_cocktails', {
        url: '/cms/cocktails',
		views:{
			main:{
				templateUrl: 'cms_cocktails.html',
		        controller: 'CmsCocktailsCtrl as cms_cocktails'
			}
		}
    })
    .state('cms_cocktail_add', {
        url: '/cms/cocktails/add',
		views:{
			main:{
				templateUrl: 'cms_cocktail_add.html',
		        controller: 'CmsCocktailAddCtrl as cms_cocktail_add'
			}
		}
    })
    .state('cms_cocktail_edit', {
        url: '/cms/cocktails/{id}',
		views:{
			main:{
				templateUrl: 'cms_cocktail_edit.html',
		        controller: 'CmsCocktailEditCtrl as cms_cocktail_edit'
			}
		}
    })
	.state('pdf_index', {
        url: '/brief-sheets',
		views:{
			main:{
				templateUrl: 'pdf_index.html',
		        controller: 'PdfController as pdf_control'
			}
		}
    })
	.state('pdf_add', {
        url: '/brief-sheets/add',
		views:{
			main:{
				templateUrl: 'pdf_create.html',
		        controller: 'PdfController as pdf_control'
			}
		}
    });
}

export default OnConfig;
