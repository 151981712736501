function CmsUsersCtrl($auth, $state, User) {
	'ngInject';

	// ViewModel
	const vm = this;
	
	vm.loadUsers = function(page) {

		var users = User.$collection({ page: page, role: 'marketing' });

		users.$refresh().$then((collection) => {
			vm.users = collection;
			vm.page = collection.$metadata.pagination.current_page;
			vm.pageSize = collection.$metadata.pagination.per_page;
			vm.total = collection.$metadata.pagination.total;
		});
	}
	
	vm.loadUsers(1);
}

export default {
  name: 'CmsUsersCtrl',
  fn: CmsUsersCtrl
};
