function CmsCategoryAddCtrl($state, $stateParams, Category, ngDialog, $scope) {
	'ngInject';

	// ViewModel
	const vm = this;
	
	vm.category = Category.$new($stateParams.id);
	vm.category = Category.$build({ name: '', title: '', subtitle: '', includeInMenu: false });
	
	vm.toggleIncludeInMenu = function() {
		
		vm.category.includeInMenu = !vm.category.includeInMenu;
	}

    vm.toggleShowMarketing = function() {

        vm.category.showMarketing = !vm.category.showMarketing;
    }
    
	vm.create = function() {
		
		vm.category.$save().$asPromise().then(function(response) {
			$state.go('cms_category_edit', { id: response.id });
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
	}
}

export default {
  name: 'CmsCategoryAddCtrl',
  fn: CmsCategoryAddCtrl
};
