function HomeCtrl($auth, $state, $stateParams, FeaturedCocktail, Category, ModalService) {
	'ngInject';

	// ViewModel
	const vm = this;



	if(null != $stateParams.auth_hash) {
		var credentials = {
			auth_hash: $stateParams.auth_hash,
		}

		// use Satellizer's $auth service to login
		$auth.login(credentials).then(function() {
			// if success, redirect to home/cms state depending on roles
			var me = User.$find('me').$then(function() {
				if(me.roles[0].slug == 'admin') {
					window.location.href = '/cms';
				} else {
					$state.go('home', { reload: true });
				}
			});
		}, function (response) {
			vm.errors = response.data.errors;
		});
	}

	vm.loadFeaturedCocktails = function() {

		var cocktails = FeaturedCocktail.$collection();

		cocktails.$refresh().$then((collection) => {
			vm.cocktails = collection.filter(function(c) {
				return c.media.length > 0;
			}).slice(0, Math.min(5, collection.length));

			for(var i=0; i < vm.cocktails.length; i++){
				vm.cocktails[i].media = vm.cocktails[i].media.filter(function(media){
                    if(media.imageType == 'website' && media.type == 'image' && media.extension == 'png') {
                        return media.imageType == 'website' && media.type == 'image' && media.extension == 'png';
                    }
                    return media.imageType == 'website' && media.type == 'image' && media.extension == 'jpg';
				});
			}
		});
	}

	vm.loadFeaturedCocktails();
}

export default {
  name: 'HomeCtrl',
  fn: HomeCtrl
};
