function CmsCocktailsCtrl($auth, $state, Cocktail) {
	'ngInject';

	// ViewModel
	const vm = this;
	
	vm.loadCocktails = function(page) {
		vm.cocktails = Cocktail.$collection({ page: page });
		vm.cocktails.$refresh().$then((collection) => {
			vm.categories = collection;
			vm.page = collection.$metadata.pagination.current_page;
			vm.pageSize = collection.$metadata.pagination.per_page;
			vm.total = collection.$metadata.pagination.total;
		});
	}
	
	vm.editCocktail = function(cocktail) {
		$state.go('cms_cocktail_edit', { id: cocktail.id });
	}
	
	vm.loadCocktails(1);
}

export default {
  name: 'CmsCocktailsCtrl',
  fn: CmsCocktailsCtrl
};
