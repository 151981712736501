function HeaderCtrl($auth, $state, $stateParams) {
	'ngInject';

	// ViewModel
	const vm = this;

}

export default {
  name: 'HeaderCtrl',
  fn: HeaderCtrl
};
