function PdfCreate($auth, $state, Pdf) {
	'ngInject';

	// ViewModel
	const vm = this;

}

export default {
	name: 'PdfCreate',
	fn: PdfCreate
};
