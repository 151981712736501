function CmsCocktailAddCtrl($state, $stateParams, Cocktail, ngDialog, Recipe, $scope) {
	'ngInject';

	// ViewModel
	const vm = this;
	
	vm.cocktail = Cocktail.$build({ name: '', method: '', history: '', featured: false });
	
	vm.toggleFeatured = function() {
		vm.cocktail.featured = !vm.cocktail.featured;
	}

    vm.toggleOnlyMarketing = function() {
        vm.cocktail.marketingOnly = !vm.cocktail.marketingOnly;
    }
	
	vm.create = function() {
		
		vm.cocktail.$save().$asPromise().then(function(response) {
			$state.go('cms_cocktail_edit', { id: response.id });
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
	}
}

export default {
  name: 'CmsCocktailAddCtrl',
  fn: CmsCocktailAddCtrl
};
