function EmailCocktailsCtrl($scope, $http) {
	'ngInject';

	// ViewModel
	const vm = this;

	vm.sendText = "Send";
	vm.enabled = true;

	function validateEmail(email) {
	    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(email);
	}

	vm.send = function() {
		
		if(!vm.enabled) {
			return;
		}
		
		if(!validateEmail(vm.email)) {
			vm.error = "You must enter a valid email address.";
		} else {
			var data = JSON.stringify({ email: vm.email, ids: $scope.cocktails.map(function(c) { return c.id; }) });

			vm.sendText = "Sending...";

			$http({
				url: '/api/v1/cocktails/email',
				method: "POST",
				data: data,
				headers: {'Content-Type': 'application/json'}
			}).then(function(success) {
				$scope.closeThisDialog();

			}, function (error) {
				console.error(error);
				vm.error = "There was a problem, please try again.";
				vm.sendText = "Send";
				vm.enabled = true;
			});

			/*	.success(function (data, status, headers, config) {
			}).error(function (data, status, headers, config) {
				vm.error = "There was a problem, please try again.";
				vm.sendText = "Send";
				vm.enabled = true;
			});*/
		}
	}
}

export default {
  name: 'EmailCocktailsCtrl',
  fn: EmailCocktailsCtrl
};