function CmsCtrl($auth, $state) {
	'ngInject';

	// ViewModel
	const vm = this;
	
	
}

export default {
  name: 'CmsCtrl',
  fn: CmsCtrl
};
