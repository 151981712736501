function InspireStateService($http) {
	'ngInject';
	
	const service = {
		categories: new Set(),
		categoryValues: new Set(),
		cachedCocktails: null
	};
	
	service.reset = function() {
		
		service.categories = new Set();
		service.categoryValues = new Set();
		service.cachedCocktails = null;
	};
	
	return service;
}

export default {
	name: 'InspireStateService',
	fn: InspireStateService
};