function OnRun($rootScope, AppSettings, $auth, $state, ModalService) {
  'ngInject';

  $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {

      if(!window.localStorage.getItem("verified_age")){
          if(!$auth.isAuthenticated()){
              ModalService.showModal({
      			templateUrl: "modals/age_verification.html",
      			controller: "AgeCtrl as age",
      		});
          }
      }


  });

  // change page title based on state
  $rootScope.$on('$stateChangeSuccess', (event, toState) => {
    $rootScope.pageTitle = '';

    if (toState.title) {
      $rootScope.pageTitle += toState.title;
      $rootScope.pageTitle += ' \u2014 ';
    }

    $rootScope.pageTitle += AppSettings.appTitle;

    // force login
    var requiredLogin = toState.name.indexOf('cms');

	if (requiredLogin >= 0 && !$auth.isAuthenticated()) {
		event.preventDefault();
		$state.transitionTo('login');
	}
  });
}

export default OnRun;
