function CmsCategoryValueAddCtrl($state, $stateParams, Category, CategoryValue, ngDialog, $scope) {
	'ngInject';

	// ViewModel
	const vm = this;

	vm.isBrandCategoryValue = false;

	vm.category = Category.$find($stateParams.category_id).$then(function() {
		vm.isBrandCategoryValue = vm.category.slug == 'brand';
	});

	vm.categoryValue = CategoryValue.$new();
	vm.categoryValue = CategoryValue.$build({ categoryId: $stateParams.category_id, name: '' });
	
	vm.create = function() {
		
		vm.categoryValue.$save().$asPromise().then(function(response) {
			$state.go('cms_category_value_edit', { id: response.id });
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
	}
}

export default {
  name: 'CmsCategoryValueAddCtrl',
  fn: CmsCategoryValueAddCtrl
};
