function CategoryCtrl($auth, $state, $stateParams, User, Category, InspireStateService, $location) {
	'ngInject';

	// ViewModel
	const vm = this;
    vm.category = {};
    vm.categories = [];
    vm.startedCat = null;
	vm.loaded = false;
	vm.isAdmin = false;
    vm.isMarketing = false;
    vm.inspire = (window.localStorage.getItem('inspire')) ? JSON.parse(window.localStorage.getItem('inspire')) : [];

	vm.isAuthenticated = function() {
		return $auth.isAuthenticated();
	};
	
	vm.checkUserRoles = function() {
		if(vm.isAuthenticated()) {
			vm.user = User.$find('me').$then(function(user) {
				var adminRoles = user.roles.filter(function(r) {
                    return r.slug == 'admin';
                });
                var marketing = user.roles.filter(function(r) {
                    return r.slug == 'marketing';
                });
				vm.isAdmin = adminRoles.length > 0;
                vm.isMarketing = marketing.length > 0;
				vm.loadCategoryValues();
			});
		}else{
			vm.loadCategoryValues();
		}
	};

	vm.loadCategoryValues = function() {
        vm.category = Category.$find($stateParams.id);

		vm.category.values.$fetch().$then((collection) => {
			vm.categoryValues = collection.filter(function(c) {
                return c.media.length > 0;
			});


			var categories = Category.$collection();

			categories.$refresh().$then((collection) => {
				vm.categories = collection.filter(function(category) {
					if(vm.isMarketing || vm.isAdmin) {
	                    return (category.showMarketing && category.media.length > 0) || (category.includeInMenu && category.media.length > 0);
	                }
	                
	                return !category.showMarketing && category.includeInMenu && category.media.length > 0;
				});

				if(!window.localStorage.getItem('categories')){
					var cat = {};
					cat[vm.category.id] = [];
					for(var i = 0; i < vm.categories.length; i++){
						if(vm.categories[i].id != vm.category.id){
							cat[vm.categories[i].id] = [];
						}
					}
					window.localStorage.setItem('categories', JSON.stringify(cat));
				}
				vm.selectedCategories = JSON.parse(window.localStorage.getItem('categories'));
				for(var i = 0; i < vm.selectedCategories[vm.category.id].length; i++){
		        	for(var j = 0; j< vm.categoryValues.length; j++){
						if(vm.selectedCategories[vm.category.id][i] == vm.categoryValues[j].id){
							vm.categoryValues[j].selected = true;
						}
					}
		        }
				vm.loaded = true;
			});
		});
	};

	vm.toggleCategoryValue = function(categoryValue) {
		if(vm.loaded){
			categoryValue.selected = categoryValue.selected == true ? false : true;

			var index = vm.selectedCategories[vm.category.id.toString()].indexOf(categoryValue.id);
			if (index > -1) {
			    vm.selectedCategories[vm.category.id.toString()].splice(index, 1);
			}else{
				vm.selectedCategories[vm.category.id.toString()].push(categoryValue.id);
			}


	        if(window.localStorage.getItem('categories')) {
	            window.localStorage.removeItem('categories');
	        }

	        window.localStorage.setItem('categories', JSON.stringify(vm.selectedCategories));
		}
	};

	vm.selectAll = function() {

		for(var i = 0; i < vm.categoryValues.length; i++) {

            vm.toggleCategoryValue(vm.categoryValues[i]);

			// InspireStateService.categoryValues.add(vm.categoryValues[i]);
		}
	};

	vm.next = function() {

		for(var i in vm.selectedCategories){
			if(vm.selectedCategories[i] != undefined && vm.selectedCategories[i].length <= 0){
				$location.path('/categories/' + i);
                return false;
			}
		}
		window.localStorage.removeItem('recommendation_ids')
		$location.path('/recommendations');
		return false;

	};
	
	vm.previous = function() {

		window.history.back();
	};

	vm.checkUserRoles();

}

export default {
  name: 'CategoryCtrl',
  fn: CategoryCtrl
};
