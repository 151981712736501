function CmsCategoryValuesCtrl($auth, $state, Category) {
	'ngInject';

	// ViewModel
	const vm = this;
	
	vm.loadCategories = function(page) {

		var categories = Category.$collection({ page: page });

		categories.$refresh().$then((collection) => {
			vm.categories = collection;
			vm.page = collection.$metadata.pagination.current_page;
			vm.pageSize = collection.$metadata.pagination.per_page;
			vm.total = collection.$metadata.pagination.total;
			
			for(var i = 0; i < vm.categories.length; i++) {
				vm.categories[i].values.$fetch();
			}
		});
	}
	
	vm.editCategoryValue = function(value) {
		
		$state.go('cms_category_value_edit', { id: value.id });
	}
	
	vm.loadCategories(1);
}

export default {
  name: 'CmsCategoryValuesCtrl',
  fn: CmsCategoryValuesCtrl
};
