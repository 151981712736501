function CmsCategoryValueEditCtrl($state, $stateParams, CategoryValue, ngDialog, $scope, Category) {
	'ngInject';

	// ViewModel
	const vm = this;

	vm.isBrandCategoryValue = false;
	vm.pdfUploadComplete = true;
	vm.uploadPercentage = 0;

	var showDialog = function(error) {

		$scope.error = error;

		var dialog = ngDialog.open({
	        template: 'dialog_error.html',
	        className: 'ngdialog-theme-default',
	        scope: $scope,
	        width: 400
	    });
	}

	vm.getCategoryValue = function(){
		vm.categoryValue = CategoryValue.$find($stateParams.id, { include: 'brand_pdf_media' }).$then(function() {
			vm.category = Category.$find(vm.categoryValue.categoryId).$then(function() {
				vm.isBrandCategoryValue = vm.category.slug == 'brand';
			});
		});

	}


	$scope.setImageMedia = function(media) {

		vm.imageMedia = media;
	}

	$scope.setBrandPdfImageMedia = function(media) {

		vm.brandPdfImageMedia = media;
	}

	vm.uploadImageMedia = function() {

		if(vm.imageMedia == undefined || vm.imageMedia.length < 1) {
			showDialog("You must select a file to upload.");
		} else {
			uploadFile(vm.imageMedia, 'category_value_id', vm.categoryValue.id, 'image');
		}
	}

	vm.uploadBrandPdfImageMedia = function() {

		if(vm.brandPdfImageMedia == undefined || vm.brandPdfImageMedia.length < 1) {
			showDialog("You must select a file to upload.");
		} else {
			var reader = new FileReader();
			if(vm.brandPdfImageMedia.files[0].size > 5000000){
				showDialog("Image must be less than 5MB");
				return;
			}
            reader.readAsDataURL(vm.brandPdfImageMedia.files[0]);
            reader.onload = function (e) {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    // console.log(this.size);
                    if(this.height != 1143 || this.width != 1911){
						showDialog("Image must be of dimensions 1911 x 1143");
						return;
					}else{
						vm.pdfUploadComplete = false;
						uploadFile(vm.brandPdfImageMedia, 'brand_pdf_category_value_id', vm.categoryValue.id, 'image');
					}
                };
            }
		}
	}

	var uploadFile = function(file, foreignKey, foreignKeyValue, type) {
		vm.uploadPercentage =0;
        var fd = new FormData()

        fd.append('media', file.files[0]);
        fd.append(foreignKey, foreignKeyValue);
        fd.append('type', type);

        var xhr = new XMLHttpRequest()

		xhr.upload.onprogress = function(e){
			if (e.lengthComputable) {
				vm.uploadPercentage = Math.round(e.loaded / e.total * 100);
				$scope.$apply();
            }
		}

        xhr.addEventListener('load', function(result) {
	        var status = result.currentTarget.status;

	        if(status == 200) {
				vm.pdfUploadComplete = true;
				vm.getCategoryValue();
		    	showDialog("Upload successful.");
	    	} else {
		    	var response = JSON.parse(result.currentTarget.response);
		    	var errors = response.errors;
		    	var message = errors[Object.keys(errors)[0]][0];

		    	if(message) {
		    		showDialog(message);
		    	} else {
			    	showDialog("There was a problem uploading your file.");
			    }
	    	}
	    }, false)
        xhr.addEventListener('error', function(result) {
	    	showDialog("Error uploading file.");
	    }, false)
        xhr.open('POST', '/api/v1/media')
        xhr.send(fd)
    }

	vm.save = function() {

		vm.categoryValue.$save(['name', 'pdfColor']).$asPromise().then(function() {
			$state.go('cms_category_values');
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
	}

	vm.del = function() {

		vm.categoryValue.$destroy().$asPromise().then(function() {
			$state.go('cms_category_values');
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
	}

	vm.getCategoryValue();
}

export default {
  name: 'CmsCategoryValueEditCtrl',
  fn: CmsCategoryValueEditCtrl
};
