function CategoriesCtrl($auth, $state, $location, User, Category, InspireStateService) {
	'ngInject';

	// ViewModel
	const vm = this;

	vm.selected = {};
	vm.isAdmin = false;
    vm.isMarketing = false;
    
	if(window.localStorage.getItem('categories')){
		window.localStorage.removeItem('categories');
	}

	vm.isAuthenticated = function() {
		return $auth.isAuthenticated();
	};

	vm.checkUserRoles = function() {
		if(vm.isAuthenticated()) {
			vm.user = User.$find('me').$then(function(user) {
				var adminRoles = user.roles.filter(function(r) {
                    return r.slug == 'admin';
                });
                var marketing = user.roles.filter(function(r) {
                    return r.slug == 'marketing';
                });
				vm.isAdmin = adminRoles.length > 0;
                vm.isMarketing = marketing.length > 0;
				vm.loadCategories();
			});
		}else{
			vm.loadCategories();
		}
	};

	vm.loadCategories = function() {

		var categories = Category.$collection();

		categories.$refresh().$then((collection) => {
			vm.categories = collection.filter(function(category) {

				if(vm.isMarketing || vm.isAdmin) {
                    return (category.showMarketing && category.media.length > 0) || (category.includeInMenu && category.media.length > 0);
                }
                
                return !category.showMarketing && category.includeInMenu && category.media.length > 0;
			});
		});
	}

	vm.toggleCategory = function(category) {

		category.selected = category.selected == true ? false : true;

		if(category.selected) {
			vm.selected[category.id] = [];
		} else {
			delete vm.selected[category.id];
		}
	}

	vm.selectAll = function() {

		for(var i = 0; i < vm.categories.length; i++) {
			vm.categories[i].selected = vm.categories[i].selected ? false : true;
			// InspireStateService.categories.add(vm.categories[i]);
			vm.selected[vm.categories[i].id] = [];
		}
	}

	vm.next = function() {

		console.log(vm.selected);
		window.localStorage.setItem('categories', JSON.stringify(vm.selected));
		for(var i in vm.selected){
			$location.path('/categories/' + i);
			return false;
		}
	}

	vm.selectCategory = function(categoryId){
		vm.selected[categoryId] = [];
		for(var i = 0; i < vm.categories.length; i++){
			if(vm.categories[i].id != categoryId){
				vm.selected[vm.categories[i].id] = [];
			}
		}
		$location.path('/categories/' + categoryId);

	}

	vm.checkUserRoles();
}

export default {
  name: 'CategoriesCtrl',
  fn: CategoriesCtrl
};
