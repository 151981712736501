function NavigationCtrl($auth, $state, Category, $location, InspireStateService, User, $scope) {
	'ngInject';

	// ViewModel
	const vm = this;

	vm.isAdmin = false;
    vm.isMarketing = false;
	vm.searchTerm = "";
	vm.user = null;

	vm.checkUserRoles = function() {
		if(vm.isAuthenticated()) {
			vm.user = User.$find('me').$then(function(user) {
				var adminRoles = user.roles.filter(function(r) {
                    return r.slug == 'admin';
                });
                var marketing = user.roles.filter(function(r) {
                    return r.slug == 'marketing';
                });
				vm.isAdmin = adminRoles.length > 0;
                vm.isMarketing = marketing.length > 0;
				vm.loadCategories();
			});
		}else{
			vm.loadCategories();
		}

	};

	vm.isAuthenticated = function() {
		return $auth.isAuthenticated();
	};

	vm.logout = function() {
		$auth.logout().then(() => {

            if(window.localStorage.getItem('auth')) {
                window.localStorage.removeItem('auth');
            }

			// force a refresh to clear state
			window.location.href = '/';
		});
	};

	vm.loadCategories = function() {

		var categories = Category.$collection();

		categories.$refresh().$then((collection) => {

			vm.categories = categories.filter(function(category) {
                if(vm.isMarketing || vm.isAdmin) {
                    return (category.showMarketing && category.media.length > 0) || (category.includeInMenu && category.media.length > 0);
                }
                return !category.showMarketing && category.includeInMenu && category.media.length > 0;
			});

			vm.categories.forEach(function(category, idx) {
				category.values.$fetch();
			});
		});


	};

	vm.search = function() {

		$state.go('cocktails_search', { search_term: vm.searchTerm }, { reload: true });
	};

	vm.showCategory = function(category) {

		if(window.localStorage.getItem('categories')){
			window.localStorage.removeItem('categories');
		}
		// add all categories after this one
		var categories = vm.categories.filter(function(c) {
			return c.order > category.order;
		});


		$state.go('category', { id: category.id, categories: vm.categories }, { reload: false });
	};

	vm.showCocktails = function(valueId) {
		$state.go('cocktails_category_value', { category_value_id: valueId }, { reload: false });
	};

	$scope.$on('login', function(event, args) {
		vm.checkUserRoles();
	});

	$scope.$broadcast('login', null);
}

export default {
  name: 'NavigationCtrl',
  fn: NavigationCtrl
};
