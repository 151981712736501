function PdfController($auth, $state, ngDialog, $scope, Pdf) {
    'ngInject';

    // ViewModel
    const vm = this;

    vm.pdfs = [];
    vm.page = 1;
    vm.collection;
    vm.pdfToUpload;
    vm.pdfTitle = "";
	vm.pdfUploadComplete = false;
	vm.pageSize = 10;
	vm.total;


    vm.loadPdfs = function(page) {
        vm.page = page;
        vm.pdfs = Pdf.$collection({ page: page });
        vm.pdfs.$refresh().$then((collection) => {

            vm.pdfs = collection;
            vm.page = collection.$metadata.pagination.current_page;
            vm.pageSize = collection.$metadata.pagination.per_page;
            vm.total = collection.$metadata.pagination.total;

        });
    };

    window.ViewModelGlobal = vm;

    vm.this = this;

    vm.loadPdfs(1);

	var showDialog = function(error) {

		$scope.error = error;

		var dialog = ngDialog.open({
			template: 'dialog_error.html',
			className: 'ngdialog-theme-default',
			scope: $scope,
			width: 400
		});
	}

	vm.showDialog = showDialog;

    vm.downloadPDF = function(pdf) {

        window.location.href = '/pdfs/download/' + pdf.id;
    }

	vm.uploadPDF = function() {

        var file = vm.pdfToUpload;

        if (file === undefined) {
        	showDialog("Please attach a file.");
        	return;
		}

		if(file.files[0].type != 'application/pdf'){
			showDialog("File uploaded MUST be a PDF.");
			return;
		}

		if (vm.pdfTitle == "") {
			showDialog("Please enter a title for this PDF file.");
			return;
		}

		vm.uploadPercentage = 0;
        vm.pdfUploadComplete = false;

		var fd = new FormData()

		fd.append('media', file.files[0]);
		fd.append('title', vm.pdfTitle);

		var xhr = new XMLHttpRequest()


		xhr.upload.onprogress = function(e){
			if (e.lengthComputable) {
				vm.uploadPercentage = Math.round(e.loaded / e.total * 100);
				$scope.$apply();
			}
		}
		xhr.addEventListener('load', function(result) {
			var status = result.currentTarget.status;

			if(status == 200) {
				vm.pdfUploadComplete = true;
				$state.go('pdf_index');
			} else {
				var response = JSON.parse(result.currentTarget.response);
				var errors = response.errors;
				var message = errors[Object.keys(errors)[0]][0];

				if(message) {
					showDialog(message);
				} else {
					showDialog("There was a problem uploading your file.");
				}
			}
		}, false)
		xhr.addEventListener('error', function(result) {
			showDialog("Error uploading file.");
		}, false)
		xhr.open('POST', '/api/v1/pdfs/create')
		xhr.send(fd)
	}

	$scope.setMedia = function(media) {

		vm.pdfToUpload = media;
	}

	$scope.setTitle = function(el) {

		vm.pdfTitle = el.value;
	}

	$scope.formatDate = function(date){

		var dateOut = new Date(date);
		return dateOut;
	};

}

export default {
    name: 'PdfController',
    fn: PdfController
};
