function CocktailsCtrl($auth, User, $state, $stateParams, Category, Cocktail, ngDialog, $scope) {
	'ngInject';

	// ViewModel
	const vm = this;

	vm.searchTerm = $stateParams.search_term;
	vm.visibleHeadings = [];
    vm.cocktails = [];
    vm.categories = [];
    vm.categoryValueIds = [];
	vm.isAdmin = false;
    vm.isMarketing = false;
    vm.inEmailMode = false;
    
	if(vm.searchTerm == undefined || vm.searchTerm.length < 1) {
		vm.searchTerm = null;
	}

	vm.toggleHeadingVisible = function(index) {
		vm.visibleHeadings[index] = !vm.visibleHeadings[index];
	}

	vm.isAuthenticated = function() {
		return $auth.isAuthenticated();
	};
	
	vm.checkUserRoles = function() {
		if(vm.isAuthenticated()) {
			vm.user = User.$find('me').$then(function(user) {
				var adminRoles = user.roles.filter(function(r) {
                    return r.slug == 'admin';
                });
                var marketing = user.roles.filter(function(r) {
                    return r.slug == 'marketing';
                });
				vm.isAdmin = adminRoles.length > 0;
                vm.isMarketing = marketing.length > 0;
				vm.loadCategories();
			});
		}else{
			vm.loadCategories();
		}
	};

	vm.loadCategories = function() {
		var categories = Category.$collection();
		categories.$refresh().$then((collection) => {
			vm.categories = categories.filter(function(category) {
				if(vm.isMarketing || vm.isAdmin) {
                    return (category.showMarketing && category.media.length > 0) || (category.includeInMenu && category.media.length > 0);
                }
                
                return !category.showMarketing && category.includeInMenu && category.media.length > 0;
			});

			vm.categories.forEach(function(category, idx) {
				vm.visibleHeadings.push(true);
				category.values.$fetch().$then(function() {
					if(undefined !== $stateParams.category_value_id) {
						for(var i = 0; i < category.values.length; i++) {
							if(category.values[i].id == $stateParams.category_value_id) {
								category.values[i].selected = true;
							} else {
								if(localStorage.getItem('category_value_ids') !== null) {
                                    var storedCVids = localStorage.getItem('category_value_ids').split(',');
                                    storedCVids.map(function(cat, key) {
                                        if(category.values[i].id == cat) {
                                            category.values[i].selected = true;
                                        }
                                    });
								}
							}
						}
					}

					if(idx == vm.categories.length - 1) {
						vm.loadCocktails(1);
					}
				});
			});
		});
	}

	vm.toggleCategoryValue = function(value) {

		if (value.selected == undefined) {
			value.selected = false;
		}
		value.selected = value.selected == true ? false : true;

		if(vm.selectedCatVals == null) {
            vm.selectedCatVals = [];
		}
		if(value.selected) {
            vm.selectedCatVals.push(value.id);
        }
        if(!value.selected || value.selected === undefined) {
            vm.selectedCatVals.map(function(cat, idx) {
                if(cat == value.id) {
                    vm.selectedCatVals.splice(idx, 1);
                }
			});
		}
        localStorage.setItem('category_value_ids', vm.selectedCatVals);
		vm.loadCocktails(1);
	};

	vm.loadCocktails = function(page) {
        vm.cocktails = [];

		vm.categoryValueIds = [];

		if(localStorage.getItem('category_value_ids').length) {
            vm.categoryValueIds = localStorage.getItem('category_value_ids').split(',');
		}

		if(vm.categories != undefined) {
			for(var i = 0; i < vm.categories.length; i++) {
				if(vm.categories[i].values == undefined) {
					continue;
				}

				for(var j = 0; j < vm.categories[i].values.length; j++) {
					var value = vm.categories[i].values[j];

                    if(localStorage.getItem('category_value_ids')) {
                        var storedCVids = localStorage.getItem('category_value_ids').split(',');
                        storedCVids.map(function(cat, key) {
                            if(value.id == cat) {
                                value.selected = true;
                            }
                        });
                    }
				}
			}
		}

        vm.cocktails = Cocktail.$collection({ page: page, 'category_value_ids[]': (vm.categoryValueIds.length) ? vm.categoryValueIds : $state.params.category_value_id, search_term: vm.searchTerm });
        vm.cocktails.$refresh().$then((collection) => {

            vm.cocktails = collection.filter(function(c) {
                return c.media.length > 0;
            });

            vm.page = collection.$metadata.pagination.current_page;
            vm.pageSize = collection.$metadata.pagination.per_page;
            vm.total = collection.$metadata.pagination.total;

            for (var i = 0; i < vm.cocktails.length; i++) {
                var media = vm.cocktails[i].media.filter(function(media){
                    if(media.imageType == 'thumbnail') {
                        return media.imageType == 'thumbnail' &&  media.type == 'image' && media.extension == 'png';
                    }
                    return media.imageType == 'website' &&  media.type == 'image' && media.extension == 'jpg';
                });
                vm.cocktails[i].media = media;
            }
        });

	};

	vm.selectCocktail = function(cocktail) {

		if(vm.inEmailMode) {
			cocktail.selected = cocktail.selected == true ? false : true;
		} else {
			$location.path('/cocktails/' + cocktail.id);
		}
	};

	vm.email = function() {

		vm.inEmailMode = true;
	};

	vm.cancelEmail = function() {

		vm.inEmailMode = false;
	};

	vm.send = function() {

		var selectedCocktails = vm.cocktails.filter(function(c) { return c.selected; });

		if(selectedCocktails.length < 1) {
			$scope.error = 'You must select at lease one cocktail to email.';

			var dialog = ngDialog.open({
				template: 'dialog_error.html',
				className: 'ngdialog-theme-default',
				scope: $scope,
				width: 400
			});
		} else {

			var newScope = $scope.$new();
			newScope.cocktails = selectedCocktails;

			var dialog = ngDialog.open({
				template: 'dialog_email.html',
				className: 'ngdialog-theme-default',
				scope: newScope,
				controller: 'EmailCocktailsCtrl as emailCocktails',
				width: 600
			});

			vm.inEmailMode = false;

		}
	};

	vm.checkUserRoles();
}

export default {
  name: 'CocktailsCtrl',
  fn: CocktailsCtrl
};
