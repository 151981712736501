function RegisterCtrl($auth, $state, User) {
	'ngInject';

	// ViewModel
	const vm = this;
	
	vm.email = "";
	vm.success = null;
	
	vm.send = function() {
		
		var user = User.$build({ email: vm.email });
		
		user.$save().$asPromise().then(function() {
			vm.email = "";
			vm.success = 'You have successfully registered - check your email to sign in.';
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});		
	}
}

export default {
  name: 'RegisterCtrl',
  fn: RegisterCtrl
};
