function CmsCategoryEditCtrl($state, $stateParams, Category, ngDialog, $scope) {
	'ngInject';

	// ViewModel
	const vm = this;
	
	var showDialog = function(error) {
		
		$scope.error = error;
	
		var dialog = ngDialog.open({ 
	        template: 'dialog_error.html', 
	        className: 'ngdialog-theme-default', 
	        scope: $scope,
	        width: 400
	    });
	}
	
	vm.category = Category.$find($stateParams.id);

	vm.toggleIncludeInMenu = function() {
		
		vm.category.includeInMenu = !vm.category.includeInMenu;
	}

    vm.toggleShowMarketing = function() {

        vm.category.showMarketing = !vm.category.showMarketing;
    }

	$scope.setImageMedia = function(media) {
		
		vm.imageMedia = media;
	}
	
	vm.uploadImageMedia = function() {

		if(vm.imageMedia == undefined || vm.imageMedia.length < 1) {
			showDialog("You must select a file to upload.");
		} else {
			uploadFile(vm.imageMedia, 'category_id', vm.category.id, 'image');
		}
	}

	var uploadFile = function(file, foreignKey, foreignKeyValue, type) {
		
        var fd = new FormData()
        
        fd.append('media', file.files[0]);
        fd.append(foreignKey, foreignKeyValue);
        fd.append('type', type);
        
        var xhr = new XMLHttpRequest()
        
        xhr.upload.addEventListener('progress', function(p) {
	     	console.log(p);   
        }, false)
        
        xhr.addEventListener('load', function(result) {
	        var status = result.currentTarget.status;
	        
	        if(status == 200) {
		    	showDialog("Upload successful.");
	    	} else {
		    	var response = JSON.parse(result.currentTarget.response);
		    	var errors = response.errors;
		    	var message = errors[Object.keys(errors)[0]][0];
		    	
		    	if(message) {
		    		showDialog(message);
		    	} else {
			    	showDialog("There was a problem uploading your file.");
			    }
	    	}
	    }, false)
        xhr.addEventListener('error', function(result) {
	    	showDialog("Error uploading file.");   
	    }, false)
        xhr.open('POST', '/api/v1/media')
        xhr.send(fd)
    }
    
	vm.save = function() {
		
		vm.category.$save(['name', 'order', 'title', 'subtitle', 'includeInMenu', 'showMarketing']).$asPromise().then(function() {
			$state.go('cms_categories');
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
	}
	
	vm.del = function() {
		
		vm.category.$destroy().$asPromise().then(function() {
			$state.go('cms_categories');
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
	}
}

export default {
  name: 'CmsCategoryEditCtrl',
  fn: CmsCategoryEditCtrl
};
