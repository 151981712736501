function AgeCtrl($auth, $state, $location,$scope, Category, InspireStateService, close) {
	'ngInject';
	// ViewModel
	const vm = this;

	//18 years in millisecs
	var yrTime = 568024668000;

	var today = new Date();
	vm.date = today;
	$scope.years = [];
	$scope.months = [
		{
			name: 'January',
			days: 31
		},
		{
			name: 'February',
			days: 28
		},
		{
			name: 'March',
			days: 31
		},
		{
			name: 'April',
			days: 30
		},
		{
			name: 'May',
			days: 31
		},
		{
			name: 'June',
			days: 30
		},
		{
			name: 'July',
			days: 31
		},
		{
			name: 'August',
			days: 31
		},
		{
			name: 'September',
			days: 30
		},
		{
			name: 'October',
			days: 31
		},
		{
			name: 'November',
			days: 30
		},
		{
			name: 'December',
			days: 31
		}
	];
	$scope.days = [];
	$scope.dob = {};


	for(var i=0; i < 100; i++){
		$scope.years.push(today.getFullYear() - i);
	}
	for(var i=1; i < 32; i++){
		$scope.days.push(i);
	}


	$scope.setAge = function(){
		console.log(vm.date.getTime());
		console.log(today.getTime());
		if(vm.date.getTime() + yrTime <  today.getTime()){
			window.localStorage.setItem("verified_age", true);
			$state.transitionTo('home');
		}else{
			console.log('not old enough');
		}
	}

	$scope.submitAge = function(){
		if(!$scope.dob.day){
			$scope.dayError = true;
		}else{
			$scope.dayError = false;
		}
		if(!$scope.dob.month){
			$scope.monthError = true;
		}else{
			$scope.monthError = false;
		}
		if(!$scope.dob.year){
			$scope.yearError = true;
		}else{
			$scope.yearError = false;
		}
		if($scope.dayError || $scope.monthError || $scope.yearError){
			$scope.error = "Validation error";
			return false;
		}
		var date = new Date();

		for(var i = 0; i < $scope.months.length; i++){
			if($scope.months[i].name == $scope.dob.month){
				if($scope.dob.day > $scope.months[i].days){
					$scope.dayError = true;
					$scope.monthError = true;
					return false;
				}
				date.setMonth(i);
			}
		}
		date.setDate($scope.dob.day);
		date.setFullYear($scope.dob.year);
		if(date.getTime() + yrTime <  today.getTime()){
			window.localStorage.setItem("verified_age", true);
			close();
		}else{
			$scope.error = "You are not old enough to access the site";
		}

	}

}

export default {
  name: 'AgeCtrl',
  fn: AgeCtrl
};
