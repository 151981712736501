function CmsCategoriesCtrl($auth, $state, Category) {
	'ngInject';

	// ViewModel
	const vm = this;
	
	vm.loadCategories = function(page) {

		var categories = Category.$collection({ page: page });

		categories.$refresh().$then((collection) => {
			vm.categories = collection;
			vm.page = collection.$metadata.pagination.current_page;
			vm.pageSize = collection.$metadata.pagination.per_page;
			vm.total = collection.$metadata.pagination.total;
		});
	}
	
	vm.editCategory = function(category) {
		
		$state.go('cms_category_edit', { id: category.id });
	}
	
	vm.loadCategories(1);
}

export default {
  name: 'CmsCategoriesCtrl',
  fn: CmsCategoriesCtrl
};
