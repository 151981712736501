function CocktailCtrl($auth, $state, Cocktail, $stateParams, User, ngDialog, $scope, $http, AppSettings) {
	'ngInject';

	// ViewModel
	const vm = this;

	vm.similarCocktails = [];
	vm.showRecipe = true;
	vm.videoUrl = null;
	vm.isMarketing = false

	vm.websiteImages=false;
	vm.websiteSmallUrl = "";
	vm.websiteLargeUrl = "";
	vm.websiteSourceUrl = "";

	vm.pdfImages=false;
	vm.pdfSmallUrl = "";
	vm.pdfLargeUrl = "";
	vm.pdfSourceUrl = "";

	vm.apiUrl = AppSettings.apiUrl;

	vm.checkUserRoles = function() {

		if(vm.isAuthenticated()) {
			var user = User.$find('me').$then(function() {
				var marketingRoles = user.roles.filter(function(r) { return r.slug == 'marketing' || r.slug == 'admin' });
				vm.isMarketing = marketingRoles.length > 0;
			});
		}
	}

	vm.isAuthenticated = function() {

		return $auth.isAuthenticated();
	}

	var randomElements = function(arr, size) {

	    var shuffled = arr.slice(0), i = arr.length, min = i - size, temp, index;

	    while (i-- > min) {
	        index = Math.floor((i + 1) * Math.random());
	        temp = shuffled[index];
	        shuffled[index] = shuffled[i];
	        shuffled[i] = temp;
	    }

	    return shuffled.slice(min);
	}

	vm.showHistoryTab = function() {

		vm.showRecipe = false;
	}

	vm.showRecipeTab = function() {

		vm.showRecipe = true;
	}

	vm.download = function() {

		window.location.href = '/cocktails/' + vm.cocktail.id + '/pdf/download?marketing=' + vm.isMarketing;
	}

	vm.loadCocktail = function() {

		vm.cocktail = Cocktail.$find($stateParams.id, { include: 'recipe,similarCocktails' }).$then(function() {

			var videos = vm.cocktail.media.filter(function(media) {
				return media.type == 'video';
			});

			var images = vm.cocktail.media.filter(function(media) {
				return media.type == 'image' && media.extension == 'png';
			});

			if(videos.length > 0) {
				vm.videoUrl = videos[0].publicUrl;
			}
			if(images.length > 0) {
				vm.imageUrl = images[0].publicUrl;
			}
			for(var i =0; i< vm.cocktail.media.length; i++){
				if(vm.cocktail.media[i].imageType == 'website' && vm.cocktail.media[i].extension == 'jpg'){
					vm.websiteSmallId = vm.cocktail.media[i].id;
					vm.websiteImages=true;
				}else if(vm.cocktail.media[i].imageType == 'website' && vm.cocktail.media[i].extension == 'png'){
					vm.websiteLargeId = vm.cocktail.media[i].id;
					vm.websiteLargeUrl = vm.cocktail.media[i].publicUrl;
					vm.websiteImages=true;
				}else if(vm.cocktail.media[i].imageType == 'website' && (vm.cocktail.media[i].extension == 'tif' || vm.cocktail.media[i].extension == 'tiff')){
					vm.websiteSourceId = vm.cocktail.media[i].id;
					vm.websiteImages=true;
				}else if(vm.cocktail.media[i].imageType == 'pdf' && vm.cocktail.media[i].extension == 'jpg'){
					vm.pdfSmallId = vm.cocktail.media[i].id;
					vm.pdfImages=true;
				}else if(vm.cocktail.media[i].imageType == 'pdf' && vm.cocktail.media[i].extension == 'png'){
					vm.pdfLargeId = vm.cocktail.media[i].id;
					vm.pdfImages=true;
				}else if(vm.cocktail.media[i].imageType == 'pdf' && (vm.cocktail.media[i].extension == 'tif' || vm.cocktail.media[i].extension == 'tiff')){
					vm.pdfSourceId = vm.cocktail.media[i].id;
					vm.pdfImages=true;
				}else if(vm.cocktail.media[i].imageType == 'mp4'){
					vm.videoUrl = vm.cocktail.media[i].publicUrl;
				}
			}


			vm.similarCocktails = randomElements(vm.cocktail.similarCocktails, Math.min(5, vm.cocktail.similarCocktails.length));

			for(var i=0; i < vm.similarCocktails.length; i++){
				vm.similarCocktails[i].media = vm.similarCocktails[i].media.filter(function(media){
					return media.imageType == 'thumbnail' && media.type == 'image';
				});
			}

			var media = vm.cocktail.media.filter(function(media){
				return media.imageType == 'website';
			});
			vm.cocktail.media = media;
		});
	}

	vm.back = function() {
		
		window.history.back();
	}

	vm.watchVideo = function() {

		if(null == vm.videoUrl) {
			$scope.error = "There is no video available for this cocktail.";

			var dialog = ngDialog.open({
		        template: 'dialog_error.html',
		        className: 'ngdialog-theme-default',
		        scope: $scope,
		        width: 400
		    });
		} else {
			$scope.videoUrl = vm.videoUrl;

			var dialog = ngDialog.open({
		        template: 'dialog_video.html',
		        className: 'ngdialog-theme-default',
		        scope: $scope,
		        width: 600
		    });
	    }
	}

	vm.email = function() {

		$scope.cocktails = [vm.cocktail];

		var dialog = ngDialog.open({
	        template: 'dialog_email.html',
	        className: 'ngdialog-theme-default',
	        scope: $scope,
	        controller: 'EmailCocktailsCtrl as emailCocktails',
	        width: 600
	    });
	}

	vm.loadCocktail();
	vm.checkUserRoles();
}

export default {
  name: 'CocktailCtrl',
  fn: CocktailCtrl
};
