function RecommendationsCtrl($auth, $state, $stateParams, Cocktail, InspireStateService, $location, ngDialog, $scope) {
	'ngInject';

	// ViewModel
	const vm = this;

	vm.inEmailMode = false;
	var categoryValueIds = [];

	var randomElements = function(arr, size) {

	    var shuffled = arr.slice(0), i = arr.length, min = i - size, temp, index;

	    while (i-- > min) {
	        index = Math.floor((i + 1) * Math.random());
	        temp = shuffled[index];
	        shuffled[index] = shuffled[i];
	        shuffled[i] = temp;
	    }

	    return shuffled.slice(min);
	}

	vm.loadRecommendations = function() {
		categoryValueIds = [];
		
		if(window.localStorage.getItem('recommendation_ids')) {
			categoryValueIds = window.localStorage.getItem('recommendation_ids').split(',');
		} else {		
	        if(window.localStorage.getItem('categories')) {
				var categories = JSON.parse(window.localStorage.getItem('categories'));
				for(var i in categories){
					for(var j = 0; j < categories[i].length; j++){
						categoryValueIds.push(categories[i][j]);
					}
				}
	            window.localStorage.removeItem('categories');
	        }
        }

		var recommendations = Cocktail.$collection({ 'category_value_ids[]': categoryValueIds });
		recommendations.$refresh().$then((collection) => {
			vm.cocktails = collection;
			for (var i = 0; i < vm.cocktails.length; i++) {
				vm.cocktails[i].media = vm.cocktails[i].media.filter(function(media){
                    if(media.imageType == 'thumbnail') {
                        return media.imageType == 'thumbnail' &&  media.type == 'image' && media.extension == 'png';
                    }
                    return media.imageType == 'website' &&  media.type == 'image' && media.extension == 'jpg';
				});
			}
		});
	}

	vm.selectCocktail = function(cocktail) {

		if(vm.inEmailMode) {
			cocktail.selected = cocktail.selected == true ? false : true;
		} else {
			window.localStorage.setItem('recommendation_ids', categoryValueIds);
			$state.go('cocktail', { id: cocktail.id });
		}
	}

	vm.email = function() {

		vm.inEmailMode = true;
	}

	vm.cancelEmail = function() {

		vm.inEmailMode = false;
	}

	vm.send = function() {

		var selectedCocktails = vm.cocktails.filter(function(c) { return c.selected; });

		if(selectedCocktails.length < 1) {
			$scope.error = 'You must select at lease one cocktail to email.';

			var dialog = ngDialog.open({
		        template: 'dialog_error.html',
		        className: 'ngdialog-theme-default',
		        scope: $scope,
		        width: 400
		    });
		} else {

			$scope.cocktails = selectedCocktails;

			var dialog = ngDialog.open({
		        template: 'dialog_email.html',
		        className: 'ngdialog-theme-default',
		        scope: $scope,
		        controller: 'EmailCocktailsCtrl as emailCocktails',
		        width: 600
		    });

			vm.inEmailMode = false;
		}
	}

	vm.loadRecommendations();

}

export default {
  name: 'RecommendationsCtrl',
  fn: RecommendationsCtrl
};
