function DateParseFilter() {

	return function(input) {
		
		if (input == null) {
			return null;
		}
		
		var date = input.date == undefined ? input : input.date;
				
		var parts = date.split('.');
		parts = parts[0].split(' ');
		
		return new Date(parts.join('T'));
	};
}

export default {
	name: 'DateParseFilter',
	fn: DateParseFilter
};