function CmsCocktailEditCtrl($state, $stateParams, $http, Cocktail, CategoryValue, ngDialog, $scope, Ingredient, ServingDetail, AppSettings) {
	'ngInject';

	// ViewModel
	const vm = this;

	vm.newIngredientText = "";
	vm.newServingDetailItem = "";
	vm.newServingDetailValue = "";
	vm.saveText = "Save";
	vm.savingText = "";

	vm.websiteImageUrl = "";
	vm.websiteUploadComplete = true;
	vm.pdfImageUrl = "";
	vm.pdfUploadComplete = true;
	vm.videoUrl = "";
	vm.videoUploadComplete = true;

	var showDialog = function(error) {

		$scope.error = error;

		var dialog = ngDialog.open({
	        template: 'dialog_error.html',
	        className: 'ngdialog-theme-default',
	        scope: $scope,
	        width: 400
	    });
	}

	vm.getCocktail = function(){
		vm.cocktail = Cocktail.$find($stateParams.id, { 'include': 'recipe,categoryValues,similarCocktails' }).$then((response) => {
			var catValueIds = [];
			for(var i =0; i< vm.cocktail.categoryValues.data.length; i++){
				catValueIds.push(vm.cocktail.categoryValues.data[i].id);
			}
			vm.cocktail.categoryValues = catValueIds;


			var simCocktailIds = [];
			for(var i =0; i< vm.cocktail.similarCocktails.length; i++){
				simCocktailIds.push(vm.cocktail.similarCocktails[i].id);
			}
			vm.cocktail.similarCocktails = simCocktailIds;

			for(var i =0; i< vm.cocktail.media.length; i++){
				if(vm.cocktail.media[i].imageType == 'website' && vm.cocktail.media[i].extension == 'png'){
					vm.websiteImageUrl = vm.cocktail.media[i].publicUrl;
				} else if(vm.cocktail.media[i].imageType == 'pdf' && vm.cocktail.media[i].extension == 'tif') {
					vm.pdfImageUrl = vm.cocktail.media[i].publicUrl;
				} else if(vm.cocktail.media[i].imageType == 'thumbnail' && vm.cocktail.media[i].extension == 'png') {
                    vm.thumbnailImageUrl = vm.cocktail.media[i].publicUrl;
                } else {
					vm.videoUrl = vm.cocktail.media[i].publicUrl;
				}

			}
		});
	};

	vm.getCocktail();

	$http({
	    method: 'GET',
	    url: AppSettings.apiUrl + '/indexcocktails'
	}).then(function successCallback(response) {
		vm.similarCocktails = response.data.filter(function(cocktail){
		 	return cocktail.id != vm.cocktail.id;
		});
	}, function errorCallback(response) {
	    console.error(response);
	});

	var category_values = CategoryValue.$collection();
	category_values.$refresh().$then((collection) => {
		vm.categoryValues = collection.$encode();
	});

	vm.toggleFeatured = function() {
		vm.cocktail.featured = !vm.cocktail.featured;
	}

    vm.toggleOnlyMarketing = function() {
        vm.cocktail.marketingOnly = !vm.cocktail.marketingOnly;
    }

	$scope.setImageMedia = function(media) {

		vm.imageMedia = media;
	}

	$scope.setVideoMedia = function(media) {

		vm.videoMedia = media;
	}

	vm.uploadImageMedia = function(imageType) {

		if(vm.imageMedia == undefined || vm.imageMedia.length < 1) {
			showDialog("You must select a file to upload.");
		} else {
			uploadFile(vm.imageMedia, 'cocktail_id', vm.cocktail.id, 'image', imageType);
		}
	}

	vm.uploadVideoMedia = function() {

		if(vm.videoMedia == undefined || vm.videoMedia.length < 1) {
			showDialog("You must select a file to upload.");
		} else {
			uploadFile(vm.videoMedia, 'cocktail_id', vm.cocktail.id, 'video');
		}
	}

	var uploadFile = function(file, foreignKey, foreignKeyValue, type, imageType='website') {

		if(type == 'image' && file.files[0].type != 'image/tiff'){
			showDialog("Image uploads must be .tif or .tiff format");
			return;
		}else if(type == 'video' && file.files[0].type != 'video/mp4'){
			showDialog("Video uploads must be .mp4 format");
			return;
		}
		vm.uploadPercentage =0;
		if(type == "image"){
			if(imageType == "website"){
				vm.websiteUploadComplete = false;
			}else{
				vm.pdfUploadComplete = false;
			}
		}else{
			vm.videoUploadComplete = false;
		}

        var fd = new FormData()

        fd.append('media', file.files[0]);
        fd.append(foreignKey, foreignKeyValue);
        fd.append('type', type);

		if(type == 'image'){
			fd.append('image_type', imageType)
		}

        var xhr = new XMLHttpRequest()


		xhr.upload.onprogress = function(e){
			if (e.lengthComputable) {
				vm.uploadPercentage = Math.round(e.loaded / e.total * 100);
				$scope.$apply();
            }
		}
        xhr.addEventListener('load', function(result) {
	        var status = result.currentTarget.status;

	        if(status == 200) {
				vm.websiteUploadComplete = true;
				vm.pdfUploadComplete = true;
				vm.videoUploadComplete = true;
				vm.getCocktail();
				showDialog("Upload successful.");
	    	} else {
		    	var response = JSON.parse(result.currentTarget.response);
		    	var errors = response.errors;
		    	var message = errors[Object.keys(errors)[0]][0];

		    	if(message) {
		    		showDialog(message);
		    	} else {
			    	showDialog("There was a problem uploading your file.");
			    }
	    	}
	    }, false)
        xhr.addEventListener('error', function(result) {
	    	showDialog("Error uploading file.");
	    }, false)
        xhr.open('POST', '/api/v1/media')
        xhr.send(fd)
    }

    vm.deleteIngredient = function(ingredient) {

		ingredient.$destroy().$asPromise().then(function() {
			for(var i = 0; i < vm.cocktail.recipe.ingredients.length; i++) {
				if(vm.cocktail.recipe.ingredients[i].id == ingredient.id) {
					vm.cocktail.recipe.ingredients.splice(i, 1);
				}
			}
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
    }

	vm.deleteServingDetail = function(servingDetail) {

		servingDetail.$destroy().$asPromise().then(function() {
			for(var i = 0; i < vm.cocktail.recipe.servingDetails.length; i++) {
				if(vm.cocktail.recipe.servingDetails[i].id == servingDetail.id) {
					vm.cocktail.recipe.servingDetails.splice(i, 1);
				}
			}
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
    }

    vm.addIngredient = function() {

		var ingredient = Ingredient.$build({ recipeId: vm.cocktail.recipe.id, text: vm.newIngredientText });

		ingredient.$save().$asPromise().then(function(response) {
			vm.cocktail.recipe.ingredients.push(response);
		    vm.newIngredientText = "";
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
    }

    vm.addServingDetail = function() {

		var servingDetail = ServingDetail.$build({ recipeId: vm.cocktail.recipe.id, item: vm.newServingDetailItem, value: vm.newServingDetailValue });

		servingDetail.$save().$asPromise().then(function(response) {
			vm.cocktail.recipe.servingDetails.push(response);
		    vm.newServingDetailItem = "";
		    vm.newServingDetailValue = "";
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
    }

	vm.save = function() {

		if (vm.newIngredientText != ''){
			vm.addIngredient();
		}
		if (vm.newServingDetailValue != '' && vm.newServingDetailItem != '') {
			vm.addServingDetail();
		}

		vm.saveText = "Saving...";
		vm.savingText = "Re-generating PDFs with updated cocktail information";

		vm.cocktail.$save(['name', 'featured', 'marketingOnly', 'history', 'recipe', 'categoryValues', 'similarCocktails']).$asPromise().then(function() {
			vm.savingText = "Done";
			vm.saveText = "Save";
			console.log(vm.cocktail);
			$state.go('cms_cocktails');
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
			vm.saveText = "Save";
		});
	}

	vm.del = function() {

		vm.cocktail.$destroy().$asPromise().then(function() {
			$state.go('cms_cocktails');
		}).catch(function(error) {
			vm.errors = error.$response.data.errors;
		});
	}
}

export default {
  name: 'CmsCocktailEditCtrl',
  fn: CmsCocktailEditCtrl
};
