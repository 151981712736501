import angular from 'angular';

// angular modules
import constants from './constants';
import onConfig  from './on_config';
import onRun     from './on_run';
import 'angular-ui-router';
import 'satellizer';
import 'angular-paging';
import 'ng-dialog';
import 'core-js';
import 'jquery-minicolors';
import 'angular-minicolors';
import 'angular-restmod';
import 'angular-restmod/dist/styles/ams.min.js';
import 'checklist-model';
import 'angular-modal-service';
import './templates';
import './filters';
import './controllers';
import './services';
import './directives';

// create and bootstrap application
const requires = [
  'ui.router',
  'restmod',
  'ngDialog',
  'minicolors',
  'satellizer',
  'checklist-model',
  'bw.paging',
  'templates',
  'angularModalService',
  'app.filters',
  'app.controllers',
  'app.services',
  'app.directives'
];

// mount on window for testing
window.app = angular.module('app', requires);

angular.module('app').constant('AppSettings', constants);

angular.module('app').config(onConfig);

angular.module('app').run(onRun);

// api models
window.app.factory('User', function(restmod) {
    return restmod.model('/users').mix({
  	    createdAt: { decode: 'DateParseFilter' },
  	    loginAt: { decode: 'DateParseFilter' },
        roles: { hasMany: 'Role', path: 'roles.data' },
    });
});

window.app.factory('Role', function(restmod) {
    return restmod.model('/roles').mix({
  	    createdAt: { decode: 'DateParseFilter' },
    });
});

window.app.factory('Cocktail', function(restmod) {
    var url = '/cocktails';
    var user = {};
    if(window.localStorage.getItem('auth')) {
        user = JSON.parse(window.localStorage.getItem('auth'));
        for(var ind = 0; ind < user.data.roles.data.length; ind++) {
            if(user.data.roles.data[ind].slug == "marketing") {
                url = 'marketing/cocktails';
            }
            if(user.data.roles.data[ind].slug == "admin") {
                url = 'cms/cocktails';
            }
        }
    }

    return restmod.model(url).mix({
  	    createdAt: { decode: 'DateParseFilter' },
        media: { hasMany: 'Media', path: 'media.data' },
        recipe: { hasOne: 'Recipe', path: 'recipe.data' },
        similarCocktails: { hasMany: 'Cocktail', path: 'similarCocktails.data' },
    });
});

window.app.factory('Category', function(restmod) {
    return restmod.model('/categories').mix({
        values: { hasMany: 'CategoryValue' },
  	    createdAt: { decode: 'DateParseFilter' },
        media: { hasMany: 'Media', path: 'media.data' },
    });
});

window.app.factory('CategoryValue', function(restmod) {
    return restmod.model('/category_values').mix({
  	    createdAt: { decode: 'DateParseFilter' },
        media: { hasMany: 'Media', path: 'media.data' },
    });
});

window.app.factory('Ingredient', function(restmod) {
    return restmod.model('/ingredients').mix({
  	    createdAt: { decode: 'DateParseFilter' },
    });
});

window.app.factory('Recipe', function(restmod) {
    return restmod.model('/recipes').mix({
  	    createdAt: { decode: 'DateParseFilter' },
        ingredients: { hasMany: 'Ingredient', path: 'ingredients.data' },
        servingDetails: { hasMany: 'ServingDetail', path: 'servingDetails.data' },
    });
});

window.app.factory('ServingDetail', function(restmod) {
    return restmod.model('/serving_details').mix({
  	    createdAt: { decode: 'DateParseFilter' },
    });
});

window.app.factory('FeaturedCocktail', function(restmod) {
    return restmod.model('/cocktails/featured').mix({
  	    createdAt: { decode: 'DateParseFilter' },
        media: { hasMany: 'Media', path: 'media.data' },
    });
});

window.app.factory('Media', function(restmod) {
    return restmod.model('/media').mix({
  	    createdAt: { decode: 'DateParseFilter' },
    });
});

window.app.factory('Pdf', function(restmod) {
    return restmod.model('/pdfs').mix({
        createdAt: { decode: 'DateParseFilter' },
    });
});

angular.bootstrap(document, ['app'], {
	strictDi: true
});
